import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero/corporate"

const CorporatePage = () => (
  <Layout>
    <Seo title="Unsere Erfahrung" />
    <Hero />
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column">
            <StaticImage
              src="../images/corporate.jpg"
              quality={65}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Clavicon Profile"
              placeholder="tracedSVG"
            />
          </div>
          <div className="column is-three-fifths">
            <div className="content">
              <h3 className="mt-0">
              كن مُستعدًا – حماية شاملة وفردية للشركات والموظفين والممتلكات
              </h3>
              <p>
              تعيش الشركات هذه الأيام حالة من الشد والجذب بين النجاح الاقتصادي وسيناريوهات التهديد المتنوعة كالتجسس والتخريب والإرهاب والجريمة اليومية أو المنظمة. لذلك فإن شركة كلافيكون تنظر إلى الأمن من منظورات مختلفة وتدعمك بحلول أمنية احترافية لتأمين مشاريعك وموظفيك وممتلكاتك.
              </p>
              <p>تشمل قائمة خدماتنا من بين أمورٍ أخرى:</p>
              <ul>
                <li>تقديم الاستشارات لأعضاء الشركات المديرين التنفيذيين في القضايا المتعلقة بالأمن.</li>
                <li>تحديد الثغرات الأمنية وتحليل المخاطر</li><li>تصميم إنشاء مفاهيم و معايير أمنية شاملة.</li>
                <li>تحليل كافة النواحي المتعلقة بالأمن المعلوماتي وتقديم المشورة بشأنها.</li>
                <li>بناء وتركيب البُنى اللازمة لإدارة الطوارئ والأزمات.</li>
                <li>تقديم الاستشارات بشأن كافة مجالات الإدارة الأمنية المتكاملة في السفر.</li>
              </ul>
              <p>
              لمزيد من التفاصيل حول باقة خدماتنا "أمن الشركات" يُمكنك التواصل معنا في أي وقت لإجراء مقابلة شخصية.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default CorporatePage
